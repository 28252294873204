import { render, staticRenderFns } from "./PotassiumKFormula.vue?vue&type=template&id=13d1484e&scoped=true&"
import script from "./PotassiumKFormula.vue?vue&type=script&lang=js&"
export * from "./PotassiumKFormula.vue?vue&type=script&lang=js&"
import style0 from "./PotassiumKFormula.vue?vue&type=style&index=0&id=13d1484e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13d1484e",
  null
  
)

export default component.exports